<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
      </div>
      <div class="headerC">
        <p>售后处理</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="myddstatu">
    <ul>
      <li :class="options.step == 0 ? 'on' : ''">
        <a href="javascript:;" @click="searchAll">全部({{ options.refundCount.all }})</a>
      </li>
      <li :class="options.step == 1 ? 'on' : ''">
        <a href="javascript:;" @click="searchSubmit">待处理({{ options.refundCount.submit }})</a>
      </li>
      <li :class="options.step == 2 ? 'on' : ''">
        <a href="javascript:;" @click="searchHanding">处理中({{ options.refundCount.handing }})</a>
      </li>
      <li :class="options.step == 3 ? 'on' : ''">
        <a href="javascript:;" @click="searchOver">完成({{ options.refundCount.over }})</a>
      </li>
    </ul>
  </div>
  <div class="clear"></div>
  <div class="myddcon">
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list v-model:loading="options.loading" :finished="options.finished" :offset="1000" finished-text="没有更多了"
        @load="onLoad">
        <div class="myddcon1" v-for="rb in options.data" :key="rb.id">
          <div class="dpbox">
            <div class="dpL">
              <router-link :to="'/memberRB/' + rb.order.id + '/' + rb.id">
                <span>{{ rb.id }}</span>
                <img src="images/mre1.png" />
              </router-link>
            </div>
            <div class="dpR">
              <a href="javascript:;" v-if="rb.type == 0">未收到拍品</a>
              <a href="javascript:;" v-else-if="rb.type == 1">拍品有破损</a>
              <a href="javascript:;" v-if="rb.type == 2">拍品与描述不符</a>
              <a href="javascript:;" v-if="rb.type == 3">其他</a>
            </div>
          </div>
          <div class="shopbox" v-for="g in rb.order.goods" :key="g.id">
            <div class="shopboxL">
              <van-image width="3.3rem" height="3.3rem" style="object-fit: cover" :src="g.goodsPicture" />
            </div>
            <div class="shopboxR">
              <div class="shopboxR_1">
                <div class="sbr1_1">{{ g.goodsName }}</div>
                <div class="sbr1_2">
                  <p class="p1" v-if="rb.order.type == 'SHOPPING'">
                    ￥{{ g.price }}
                  </p>
                  <p class="p2">￥{{ g.preferentialPrice }}</p>
                </div>
              </div>
              <div class="shopboxR_2">
                <p class="p3" v-if="rb.order.type == 'SHOPPING'">来源：商城</p>
                <p class="p3" v-else-if="rb.order.type == 'AUCTION'">
                  来源：拍卖
                </p>
                <p class="p3" v-else>其他</p>
                <p class="p4">×{{ g.quantity }}</p>
              </div>
            </div>
          </div>
          <div class="dphjbox" style="height: 3rem">
            <label style="color: red">原因:</label>
            <p>{{ rb.explain }}</p>
          </div>
          <div class="dpbtn">
            <div class="dpbtn1">
              <img src="images/dat.png" />
              <span>{{
                stringUtils.dateFormat(rb.createTime, "yyyy-MM-DD HH:mm")
              }}</span>
            </div>
            <div class="dpbtn2" v-if="rb.status == 0">
              <router-link :to="'/memberRB/' + rb.order.id + '/' + rb.id">待处理</router-link>
            </div>
            <div class="dpbtn2" v-if="rb.status == 1 || rb.status == 2">
              <router-link :to="'/memberRB/' + rb.order.id + '/' + rb.id">处理中</router-link>
            </div>
            <div class="dpbtn2" v-if="rb.status == 3">
              <router-link :to="'/memberRB/' + rb.order.id + '/' + rb.id">发货</router-link>
            </div>
            <div class="dpbtn3" v-if="rb.status == 4">
              <router-link :to="'/memberRB/' + rb.order.id + '/' + rb.id">完成</router-link>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import orderApi from "@/api/orderApi";
import configUtils from "@/config/configUtils";
import { useStore } from "vuex";
export default {
  setup() {
    const options = reactive({
      data: [],
      pageCount: 1,
      refreshing: false,
      loading: false,
      finished: false,
      total: 0,
      pageNo: 1,
      refundCount: {
        submit: 0,
        handing: 0,
        over: 0,
        all: 0,
      },
      step: 0,
    });
    const params = reactive({
      draw: 1,
      pageNo: 0,
      length: 10,
      status: "",
      handle: ""
    });
    const store = useStore();
    store.commit('setRefund');
    options.refundCount = computed(() => store.state.common.refundCount);
    const onRefresh = async () => {
      options.finished = false;
      options.loading = true;
      options.refreshing = true;
      onLoad();
    };
    const onLoad = async () => {
      console.log("refreshing:" + options.refreshing);
      if (options.refreshing) {
        options.data = [];
        options.pageCount = 1;
        options.pageNo = 1;
        options.refreshing = false;
      } else {
        if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }
      params.pageNo = (options.pageNo - 1)
      if (
        options.data.length > 0 &&
        options.total > 0 &&
        options.data.length >= options.total
      ) {
        options.finished = true;
        return;
      }
      await orderApi.memberRefundSearch(params).then((res) => {
        if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(res.recordsTotal / configUtils.page.size);
          options.pageCount = count;
          if (res.recordsTotal == 0) {
            options.data = res.data;
          } else {
            res.data.forEach((item) => {
              if (options.data.length < res.recordsTotal) {
                options.data.push(item);
              }
            });
          }
        }
        options.loading = false;
        if (options.data.length >= options.total) {
          options.finished = true;
        }
      });
    };
    const searchAll = () => {
      params.handle = "";
      params.status = "";
      options.step = 0;
      onRefresh();
    };
    const searchSubmit = () => {
      params.handle = "";
      params.status = "0";
      options.step = 1;
      onRefresh();
    };
    const searchHanding = () => {
      params.handle = "member";
      params.status = "";
      options.step = 2;
      onRefresh();
    };
    const searchOver = () => {
      params.handle = "";
      params.status = "3";
      options.step = 3;
      onRefresh();
    };

    return {
      options,
      onRefresh,
      onLoad,
      searchAll,
      searchSubmit,
      searchHanding,
      searchOver,
    };
  },
};
</script>
<style>
.p5 code {
  color: #ff2150;
}
</style>