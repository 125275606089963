<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>订单详情</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="hbox"></div>
  <div class="jsaddress" style="height: 2.8rem;">
    <a href="javascript:;" >
      <div class="jsaddressL" v-if="options.item.orderAddress != null">
        <p class="p6">
          收货人：{{ options.item.orderAddress.name
          }}<span>{{ options.item.orderAddress.mobileNo }}</span>
        </p>
        <p class="p5">
          {{
            options.item.orderAddress.province +
            options.item.orderAddress.city +
            options.item.orderAddress.region +
            options.item.orderAddress.address
          }}
        </p>
      </div>
      <div class="jsaddressR">
        <!-- <img src="images/more.png" /> -->
      </div>
      <div class="clear"></div>
    </a>
  </div>
  <div class="w100">
    <img src="images/addressline.png" />
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="jsdingdan" v-for="g in options.item.goods" :key="g.id">
    <div class="jsdp">
      <a href="javascript:;">
        <span>{{ g.shopName }}</span>
      </a>
    </div>
    <div class="jsxq">
      <div class="jsxq_1">
        <van-image width="3rem" height="3rem" :src="g.goodsPicture" />
      </div>
      <div class="jsxq_2">
        <div class="js_1">
          <p class="p1">{{ g.goodsName }}</p>
          <p class="p2">￥{{ g.preferentialPrice }}</p>
        </div>
        <div class="js_2">
          <p class="p3" v-if="options.item.type == 'SHOPPING'">
            颜色：白色；尺码：L
          </p>
          <p class="p4">×{{ g.quantity }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="jsyf" v-if="options.item.mailPrice>0">
      <div class="jsyfL">快递运费：</div>
      <div class="jsyfR">￥{{stringUtils.fixedFormat(options.item.mailPrice)}}</div>
  </div>
  <!-- <div class="jsyf">
      <div class="jsyfL">快递运费：</div>
      <div class="jsyfR">全国包邮</div>
    </div>
    <div class="jsyf">
      <div class="jsyfL">买家留言：</div>
      <div class="jsyfC">订单补充说明...</div>
    </div> -->
  <div class="jshj">
    <div class="jshjp">
      <span class="sp1">合计：</span
      ><span class="sp2">￥{{ stringUtils.fixedFormat(options.item.preferentialAmount+options.item.mailPrice) }}</span>
    </div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="kbox"></div>
  <deliver-view :order="options.item.orderDeliverGoodsVo"></deliver-view>
  <!-- <div class="jsyhq">
    <div class="jsyhq_1">
      <p class="p1">优惠券</p>
      <p class="p2">无可用</p>
    </div>
    <div class="jsyhq_2">
      <div class="jsjfL">
        <p>积分抵用<span>共150积分，可抵1.5元</span></p>
      </div>
      <div class="jsjfR">
        <div class="gwccheck"></div>
      </div>
    </div>
  </div> -->
  <div class="fbox2"></div>
  <div class="hejiBox jiesuan">
    <div class="heji">
      <div class="heji_3">
        <p>
          总计：<span>￥{{ options.item.preferentialAmount }}</span>
        </p>
      </div>
      <div class="heji_5">
        <a
          href="javascript:;"
          v-if="options.item.status == 'distribution'"
          @click="openDeliver(options.item)"
          >发货</a
        >
        <a
          href="javascript:;"
          v-if="options.item.status == 'submit'"
          @click="confirm(options.item.id)"
          >确认</a
        >
      </div>
    </div>
  </div>
  <van-popup
    v-model:show="deliverOptions.isDeliverShow"
    :style="{ height: '48vh',width:'100%' }"
    round
    position="top"
  >
    <div class="xzsize">
      <div class="xzsize0">
        <div class="size1">
          <div class="size1_2">
            <p class="p1">订单号：{{ deliverOptions.order.id }}</p>
            <p class="p2">
              收货人：{{ deliverOptions.order.orderAddress.name }} 联系电话：{{deliverOptions.order.orderAddress.mobileNo}} 合计：￥{{
                stringUtils.fixedFormat(deliverOptions.order.preferentialAmount)
              }}
            </p>
            <p class="p3">
              收货地址：{{
                deliverOptions.order.orderAddress.province +
                deliverOptions.order.orderAddress.city +
                deliverOptions.order.orderAddress.region +
                deliverOptions.order.orderAddress.address
              }}
            </p>
          </div>
          <div class="size1_3">
            <img
              src="images/close.png"
              @click="deliverOptions.isDeliverShow = false"
            />
          </div>
        </div>
        <div class="size2">
          
          <div class="size2_4" @click="deliverOptions.isCompanyShow = true">
            <p class="tit">快递公司</p>
            <div
              class="pric"
              v-if="deliverOptions.deliverParams.logisticsName == ''"
            >
              亲，请选择快递公司
            </div>
            <div class="pric" v-else>
              {{ deliverOptions.deliverParams.logisticsName }}
            </div>
          </div>
          <div class="size2_4">
            <p class="tit">快递单号</p>
            <div class="pric">
              <input
                type="text"
                v-model="deliverOptions.deliverParams.logisticsNo"
              />
            </div>
          </div>
          <div class="size2_4">
            <p class="tit">发货地址</p>
            <div class="pric">
              <input
                type="text"
                v-model="deliverOptions.deliverParams.from"
              />
            </div>
          </div>
          <div class="size3">
            <a href="javascript:;" @click="deliver">发货</a>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
  <van-popup v-model:show="deliverOptions.isCompanyShow" round position="bottom">
    <van-cascader
      v-model="deliverOptions.deliverParams.logisticsCode"
      title="请选择快递公司"
      :options="deliverOptions.deliverCompany"
      @close="deliverOptions.isCompanyShow = false"
      @finish="onFinish"
    />
  </van-popup>
</template>

<script>
import { useRoute } from "vue-router";
import orderApi from "@/api/orderApi";
import { reactive } from "vue";
import deliverModel from "@/model/order/deliverModel";
import DeliverView from "@/components/order/deliverView.vue";
export default {
  setup() {
    const route = useRoute();
    const options = reactive({
      item: {
        orderAddress: null,
        orderDeliverGoodsVo:null,
      },
      addresss: [],
      orderId: route.params.id,
      isShow: false,
    });
    const get=()=>{
      orderApi.get({ orderId: options.orderId }).then((res) => {
        if (res.status == 200) {
          options.item = res.result;
        }
      });
    }
    get();
    const { confirm, deliver, openDeliver,onFinish, deliverOptions } = deliverModel(get);
    return {
      options,
      deliverOptions,
      openDeliver,
      deliver,
      confirm,
      onFinish
    };
  },
    components: { DeliverView }
};
</script>

<style>
.sx_1 img {
  float: right;
  width: 1rem;
  margin-top: 0.5rem;
}
</style>