import { Toast, Dialog } from 'vant';
import orderApi from "@/api/orderApi";
import { reactive } from "vue";
import { useStore } from "vuex";

const deliverModel = (refresh) => {
  const store = useStore();
  const deliverOptions = reactive({
    deliverParams: {
      orderId: "",
      logisticsNo: "",
      logisticsName: "",
      logisticsCode: "",
      from: ''
    },
    company: null,
    deliverCompany: [],
    delivers:[],
    order: {},
    isDeliverShow: false,
    isCompanyShow: false,
  })
  const confirm = (id) => {
    orderApi.confirm({ orderId: id }).then((res) => {
      if (res.status == 200) {
        refresh();
        store.commit('setOrderCount');
      } else {
        Toast.fail(res.message);
      }
    });
  };
  const openDeliver = (item) => {
    deliverOptions.order = item;
    deliverOptions.deliverParams = {
      orderId: item.id,
      logisticsNo: "",
      logisticsName: "",
      logisticsCode: "",
      from: ''
    };
    deliverOptions.isDeliverShow = true;
  };
  const deliver = () => {
    if (deliverOptions.deliverParams.logisticsName == "") {
      Toast.fail("亲，请选择快递公司！");
      return;
    }
    if (deliverOptions.deliverParams.logisticsNo == "") {
      Toast.fail("亲，快递单号不能为空！");
      return;
    }
    Dialog.confirm({
      title: "订单发货",
      message: "亲，您确认要发货此订单？",
    }).then(() => {
      orderApi.deliver(deliverOptions.deliverParams).then((res) => {
        if (res.status == 200) {
          refresh();
          store.commit('setOrderCount');
          deliverOptions.isDeliverShow = false;
        } else {
          Toast.fail(res.message);
        }
      });
    });
  };
  const getAllCompany = () => {
    orderApi.getAllCompany().then(res => {
      if (res.status == 200) {
        deliverOptions.deliverCompany = res.result;
      }
    });
  }
  const onFinish = ({ selectedOptions }) => {
    deliverOptions.isCompanyShow = false;
    deliverOptions.deliverParams.logisticsName = selectedOptions.map((option) => option.text).join('/');
  };
  getAllCompany();
  return {
    deliverOptions,
    confirm,
    openDeliver,
    deliver,
    onFinish
  }

}

export default deliverModel;