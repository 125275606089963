<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>售后服务</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="rb_step">
    <van-steps :active="options.item.status">
      <van-step>提交</van-step>
      <van-step>商户处理中</van-step>
      <van-step>平台处理</van-step>
      <van-step>用户发货中</van-step>
      <van-step>完成</van-step>
    </van-steps>
  </div>
  <div
    style="
      margin-top: 3rem;
      right: 0px;
      height: 85vh;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      background-color: #fff;
    "
  >
    <div
      class="pingjiabox1"
      v-for="goods in options.order.goods"
      :key="goods.id"
    >
      <div class="pjleft">
        <van-image width="3rem" height="3rem" :src="goods.goodsPicture" />
      </div>
      <div class="pjright">
        <p class="p1">{{ goods.goodsName }}</p>
        <p class="p2"></p>
        <p class="p3">
          ￥{{ stringUtils.fixedFormat(options.order.preferentialAmount) }}
        </p>
      </div>
    </div>
    <div class="clear"></div>
    <div class="sx_2" style="background-color: #fff">
      <p class="tit">售后类型</p>
      <a href="javascript:;" v-if="options.item.type == 0">未收到拍品</a>
      <a href="javascript:;" v-if="options.item.type == 1">拍品有破损</a>
      <a href="javascript:;" v-if="options.item.type == 2">拍品与描述不符</a>
      <a href="javascript:;" v-if="options.item.type == 3">其他</a>
      <a href="javascript:;" v-if="options.item.type == 4">七天无理由退货</a>
    </div>
    <div class="clear"></div>

    <div class="sx_2" style="background-color: #fff">
      <p class="tit">退款方式</p>
      <a
        href="javascript:;"
        :class="options.item.reason == 0 ? 'on' : ''"
        @click="options.item.reason = 0"
        >全额退款</a
      >
      <a
        href="javascript:;"
        :class="options.item.reason == 3 ? 'on' : ''"
        @click="options.item.reason = 3"
        >其他（平台处理）</a
      >
    </div>
    <div class="sx_2" style="background-color: #fff">
      <p class="tit">退款金额</p>
      <div class="pric" style="background: #eee;width: 30%;padding-left: .5rem;" @click="options.isAmoutShow = true">
        {{ options.item.amount }}
      </div>
    </div>
    <div class="pingjiabox2">
      <div class="pjbox2_l">退货理由描述</div>
      <div class="pjbox2_r"></div>
      <div class="clear"></div>
      <div class="yijian">
        <textarea
          name=""
          cols=""
          rows=""
          readonly
          v-model="options.item.explain"
          placeholder="请输入您的退货理由"
        ></textarea>
      </div>
      <div class="picture">
        <img :src="f.url" v-for="(f,index) in options.fileList" @click="openAPreview(index)" :key="f" />
      </div>
    </div>
    <div class="clear"></div>
    <div class="pingjiabox2">
      <div class="pjbox2_l">处理理由</div>
      <div class="pjbox2_r"></div>
      <div class="clear"></div>
      <div class="yijian">
        <textarea
          name=""
          cols=""
          rows=""
          v-model="options.item.remark"
          placeholder="请输入您的处理理由"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="fbox2"></div>
  <div class="hejiBox jiesuan">
    <div class="heji">
      <div class="fabiao" v-if="options.item.status == 1">
        <a href="javascript:;" @click="toSystem">处理</a>
      </div>
      <div class="fabiao" v-if="options.item.status == 0">
        <a href="javascript:;" @click="handle">确认处理</a>
      </div>
      <div class="fabiao" v-if="options.item.status == 2">
        <a href="javascript:;" >平台处理{{ options.item.amount }}</a>
      </div>
      <div class="fabiao" v-if="options.item.status == 3">
        <a href="javascript:;"  @click="receipt">确认收货</a>
      </div>
      <div class="fabiao" v-if="options.item.status == 4">
        <a href="javascript:;" >已完成</a>
      </div>
    </div>
  </div>
  <deliver-view :deliverInfo="options.item.deliverGoods"></deliver-view>

  <van-popup v-model:show="options.isAmoutShow" :overlay="false" round position="bottom">
    <van-number-keyboard
      v-if="options.isAmoutShow"
      v-model="options.item.amount"
      :z-index="2008"
      theme="custom"
      extra-key="."
      :hide-on-click-outside="true"
      close-button-text="完成"
      :show="options.isAmoutShow"
      @blur="amountV"
    />
  </van-popup>
  <van-image-preview
    v-model:show="options.isImgPreviewShow"
    :startPosition="options.aimgIndex"
    :images="options.aimages"
    @change="onAChange"
  >
    <template v-slot:index>第{{ options.aimgIndex + 1 }}幅</template>
  </van-image-preview>
</template>

<script>
import { useRoute } from "vue-router";
import orderApi from "@/api/orderApi";
import { reactive } from "@vue/reactivity";
import { useStore } from 'vuex'
import { Toast, Dialog } from "vant";
import deliverView from '@/components/order/deliverView.vue';
export default {
  components: { deliverView },
  setup() {
    const route = useRoute();
    const options = reactive({
      order: {
        id: "",
        preferentialAmount: 0,
        goods: [],
      },
      item: {
        id: route.params.Id,
        reason: 0,
        amount:'',
        remark:'',
        deliverGoods:null
      },
      send: {
        id: route.params.Id,
        reason: 0,
        amount: 0,
        remark: "",
      },
      step: 0,
      fileList: [],
      isAmoutShow: false,
      isImgPreviewShow:false,
      aimgIndex:0,
      aimages:[]
    });
    const store = useStore();
    const onAChange = (newIndex) => {
      options.aimgIndex = newIndex;
    };
    const openAPreview = (index) => {
      options.aimgIndex = index;
      options.aimages=[];
      options.fileList.forEach((item)=>{
        options.aimages.push(item.url);
      })
      options.isImgPreviewShow = true;
    };
    const get = () => {
      orderApi.getRefund({ id: options.item.id }).then((res) => {
        if (res.status == 200) {
          options.item = res.result;
          options.item.amount=options.item.amount.toString();
          options.order = options.item.order;
          options.fileList =
            options.item.picInfo == "" ? "" : JSON.parse(options.item.picInfo);
        }
      });
    };
    const handle = () => {
      orderApi.merchantRefundHandle({ id: options.item.id }).then((res) => {
        if (res.status == 200) {
          get();
          store.commit('setRefund');
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const receipt=()=>{
      Dialog.confirm({
        title: "确认收货",
        message: "亲，您确认要收货【" + options.item.id + "】？",
      }).then(() => {
        orderApi.myMerchantRefundReceipt({id:options.item.id}).then(res=>{
            if(res.status==200){
              get();
              store.commit('setRefund');
            }else{
                Toast.fail(res.message);
            }
        })
      });
    }
    const amountV=()=>{
      if(options.item.amount==''||parseFloat(options.item.amount)==0){
        Toast.fail("亲，请填写退款金额（金额不可为0）");
        return;
      }
      if (
        parseFloat(options.item.amount) >
        parseFloat(options.order.preferentialAmount)
      ) {
        Toast.fail("亲，退款金额超出了退款额度");
        return;
      }
      options.isAmoutShow=false
    }
    const toSystem = () => {
      console.log(options.item.remark)
      if(options.item.amount==''||parseFloat(options.item.amount)==0){
        Toast.fail("亲，请填写退款金额（金额不可为0）");
        return;
      }
      if (
        parseFloat(options.item.amount) >
        parseFloat(options.order.preferentialAmount)
      ) {
        Toast.fail("亲，退款金额超出了退款额度");
        return;
      }
      if (options.item.remark == ""||options.item.remark==null) {
        Toast.fail("亲，请处理理由");
        return;
      }
      
      options.send = {
        id: route.params.Id,
        reason: options.item.reason,
        amount: options.item.amount,
        remark: options.item.remark,
      };
      Dialog.confirm({
        title: "售后处理",
        message: "亲，您确认要处理【" + options.item.id + "】？",
      }).then(() => {
        orderApi.merchantRefundFinish(options.send).then((res) => {
          if (res.status != 200) {
            store.commit('setRefund');
            Toast.fail(res.message);
          } else {
            get();
          }
        });
      });
    };
    get();
    return {
      options,
      handle,
      toSystem,
      onAChange,
      openAPreview,
      receipt,
      amountV
    };
  },
};
</script>

<style>
.sx_2 .on {
  color: #fff !important;
  background: #ff2150 !important;
}
</style>