<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>商家订单</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="shopType" style="top: 2.8rem !important">
    <ul>
      <li :class="params.status == '' && params.payStatus == '' ? 'on' : ''">
        <a href="javascript:;" @click="changeSearch('')"
          >全部({{
            options.orderCount.submit +
            options.orderCount.distribution +
            options.orderCount.delivery +
            options.orderCount.receiving +
            options.orderCount.receivingsuccess
          }})</a
        >
      </li>
      <li :class="params.status == 'submit' ? 'on' : ''">
        <a href="javascript:;" @click="changeSearch('submit')"
          >待确认({{ options.orderCount.submit }})</a
        >
      </li>
      <li :class="params.status == 'distribution' ? 'on' : ''">
        <a href="javascript:;" @click="changeSearch('distribution')"
          >待发货({{ options.orderCount.distribution }})</a
        >
      </li>
      <li :class="params.status == 'receiving' ? 'on' : ''">
        <a href="javascript:;" @click="changeSearch('receiving')"
          >待收货({{ options.orderCount.receiving }})</a
        >
      </li>
    </ul>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="myddcon">
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="options.loading"
        :finished="options.finished"
        :offset="1000"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="myddcon1" v-for="o in options.orders" :key="o.id">
          <div class="dpbox">
            <div class="dpL">
              <router-link :to="'/merchantOrderInfo/' + o.id">
                <span>{{ o.id }}</span>
                <img src="images/mre1.png" />
              </router-link>
            </div>
            <div class="dpR">
              <a href="javascript:;" v-if="o.payStatus == 'No'">待付款</a>
              <a
                href="javascript:;"
                v-if="o.payStatus == 'Yes' && o.status == 'submit'"
                >待确认</a
              >
              <a
                href="javascript:;"
                v-if="o.payStatus == 'Yes' && o.status == 'distribution'"
                >待配货</a
              >
              <a
                href="javascript:;"
                v-if="o.payStatus == 'Yes' && o.status == 'delivery'"
                >待发货</a
              >
              <a
                href="javascript:;"
                v-if="o.payStatus == 'Yes' && o.status == 'receiving'"
                >待收货</a
              >
              <a
                href="javascript:;"
                v-if="o.payStatus == 'Yes' && o.status == 'receivingsuccess'"
                >已收货</a
              >
              <a
                href="javascript:;"
                v-if="o.payStatus == 'Yes' && o.status == 'refund'"
                >已退款</a
              >
            </div>
          </div>
          <div class="shopbox" v-for="g in o.goods" :key="g.id">
            <div class="shopboxL">
              <van-image
                width="3.3rem"
                height="3.3rem"
                style="object-fit: cover"
                :src="g.goodsPicture"
              />
            </div>
            <div class="shopboxR">
              <div class="shopboxR_1">
                <div class="sbr1_1">{{ g.goodsName }}</div>
                <div class="sbr1_2">
                  <p class="p1" v-if="o.type == 'SHOPPING'">￥{{ g.price }}</p>
                  <p class="p2">￥{{ g.preferentialPrice }}</p>
                </div>
              </div>
              <div class="shopboxR_2">
                <p class="p3" v-if="o.type == 'SHOPPING'">来源：商城</p>
                <p class="p3" v-else-if="o.type == 'AUCTION'">来源：拍卖</p>
                <p class="p3" v-else>来源：其他</p>
                <p class="p4">×{{ g.quantity }}</p>
              </div>
            </div>
          </div>
          <div class="dphjbox">
            <p class="p5" v-if="o.mailPrice > 0">
              邮费：<span>￥{{ stringUtils.fixedFormat(o.mailPrice) }}</span>
              合计:<span
                >￥{{
                  stringUtils.fixedFormat(o.preferentialAmount + o.mailPrice)
                }}</span
              >
            </p>
            <p class="p5">
              合计:<span
                >￥{{
                  stringUtils.fixedFormat(o.preferentialAmount + o.mailPrice)
                }}</span
              >
            </p>
          </div>
          <div class="dpbtn">
            <div class="dpbtn1">
              <img src="images/dat.png" />
              <span>{{
                stringUtils.dateFormat(o.createTime, "yyyy-MM-DD HH:mm:ss")
              }}</span>
            </div>
            <div class="dpbtn2" v-if="o.status == 'submit'">
              <a href="javascript:;" @click="confirm(o.id)">确认订单</a>
            </div>
            <div class="dpbtn2" v-if="o.status == 'distribution'">
              <a href="javascript:;" @click="openDeliver(o)">发货 </a>
            </div>
            <div class="dpbtn3" v-if="o.status == 'receiving'">
              <router-link :to="'/infoDeliver/' + o.id">查看物流</router-link>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
  <van-popup
    v-model:show="deliverOptions.isDeliverShow"
    :style="{ height: '48vh', width: '100%' }"
    round
    position="top"
  >
    <div class="xzsize">
      <div class="xzsize0">
        <div class="size1">
          <div class="size1_2">
            <p class="p1">订单号：{{ deliverOptions.order.id }}</p>
            <p class="p2">
              收货人：{{ deliverOptions.order.orderAddress.name }} 联系电话：{{
                deliverOptions.order.orderAddress.mobileNo
              }}
              合计：￥{{
                stringUtils.fixedFormat(deliverOptions.order.preferentialAmount)
              }}
            </p>
            <p class="p3">
              收货地址：{{
                deliverOptions.order.orderAddress.province +
                deliverOptions.order.orderAddress.city +
                deliverOptions.order.orderAddress.region +
                deliverOptions.order.orderAddress.address
              }}
            </p>
          </div>
          <div class="size1_3">
            <img
              src="images/close.png"
              @click="deliverOptions.isDeliverShow = false"
            />
          </div>
        </div>
        <div class="size2">
          <div class="size2_4" @click="deliverOptions.isCompanyShow = true">
            <p class="tit">快递公司</p>
            <div
              class="pric"
              v-if="deliverOptions.deliverParams.logisticsName == ''"
            >
              亲，请选择快递公司
            </div>
            <div class="pric" v-else>
              {{ deliverOptions.deliverParams.logisticsName }}
            </div>
          </div>
          <div class="size2_4">
            <p class="tit">快递单号</p>
            <div class="pric">
              <input
                type="text"
                v-model="deliverOptions.deliverParams.logisticsNo"
              />
            </div>
          </div>
          <div class="size2_4">
            <p class="tit">发货地址</p>
            <div class="pric">
              <input type="text" v-model="deliverOptions.deliverParams.from" />
            </div>
          </div>
          <div class="size3">
            <a href="javascript:;" @click="deliver">发货</a>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
  <van-popup
    v-model:show="deliverOptions.isCompanyShow"
    round
    position="bottom"
  >
    <van-cascader
      v-model="deliverOptions.deliverParams.logisticsCode"
      title="请选择快递公司"
      :options="deliverOptions.deliverCompany"
      @close="deliverOptions.isCompanyShow = false"
      @finish="onFinish"
    />
  </van-popup>
  <to-top></to-top>
</template>

<script>
import memberModel from "@/model/memberModel";
import { reactive, computed } from "vue";
import orderApi from "@/api/orderApi";
import { useStore } from "vuex";
import ToTop from "@/components/ToTop.vue";
import deliverModel from "@/model/order/deliverModel";
export default {
  components: { ToTop },
  setup() {
    const options = reactive({
      orders: [],
      pageCount: 1,
      pageNo: 1,
      refreshing: false,
      loading: false,
      finished: false,
      total: 0,
      orderCount: {
        submit: 0,
        distribution: 0,
        delivery: 0,
        receiving: 0,
        receivingsuccess: 0,
      },
      item: {
        id: "",
      },
    });
    const params = reactive({
      draw: 1,
      pageNo: 0,
      length: 10,
      status: "",
    });
    const store = useStore();
    options.orderCount = computed(() => store.state.common.merchantOrderCount);
    async function onRefresh() {
      options.finished = false;
      options.loading = true;
      options.refreshing = true;
      onLoad();
    }
    async function onLoad() {
      console.log("refreshing:" + options.refreshing);
      if (options.refreshing) {
        options.orders = [];
        options.total = 0;
        options.pageCount = 1;
        options.pageNo = 1;
        options.refreshing = false;
      } else {
        if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }
      params.pageNo = (options.pageNo - 1);
      if (
        options.orders.length > 0 &&
        options.total > 0 &&
        options.orders.length >= options.total
      ) {
        options.finished = true;
        return;
      }
      await orderApi.searchMerchant(params).then((res) => {
        if (res.status == 200) {
          options.total = res.recordsTotal;
          let count = Math.ceil(res.recordsTotal / params.length);
          options.pageCount = count;
          if (res.recordsTotal == 0) {
            options.orders = res.data;
          } else {
            if (res.data.length > 0) {
              res.data.forEach((item) => {
                if (options.orders.length < res.recordsTotal) {
                  options.orders.push(item);
                }
              });
            } else {
              options.finished = true;
            }
          }
        }
        options.loading = false;
        if (options.orders.length >= options.total) {
          options.finished = true;
        }
      });
    }
    function changeSearch(value) {
      params.status = value;
      onRefresh();
    }

    const { isLogin } = memberModel();
    const { confirm, deliver, openDeliver, onFinish, deliverOptions } =
      deliverModel(onRefresh);
    isLogin();
    return {
      options,
      deliverOptions,
      params,
      onRefresh,
      onLoad,
      changeSearch,
      deliver,
      confirm,
      openDeliver,
      onFinish,
    };
  },
};
</script>

<style>
</style>